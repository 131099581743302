import 'bootstrap';

import JQuery from "jquery";
window.$ = window.JQuery = JQuery;

import {jsMainBottomBarInit} from  './baseBottomBar';


const jsMain = {


    // region CORE

    bottomBarOriginalTop : 0,



    /**
     * Entry point
     */
    init : function () {

        this.bindCmp();
        this.attachEvents();
    },

    /**
     * Collega i componenti
     */
    bindCmp : function () {

    },

    /**
     * Collega gli eventi
     */
    attachEvents : function () {

        const th = this;
    },

    // endregion



    // region PRIVATE METHODS


    // endregion

}


$(function () {

    jsMain.init();
    jsMainBottomBarInit();
});



