

const jsMainBottomBar = {


    // region CORE

    bottomBarOriginalTop : 0,


    /**
     * Entry point
     */
    init : function () {

        const th = this;

        this.jqMainBottombarContainer = $('.main-bottombar-container');
        this.bottomBarOriginalTop = this.jqMainBottombarContainer.offset().top;
        this.endBodyReference = $('#end-body-reference');

        $(window).off('resize').on('resize', function () {

            th.setBottomBarPosition();
        });

        this.setBottomBarPosition();
        this.jqMainBottombarContainer.css('opacity', 1.0);
    },

    // endregion



    // region PRIVATE METHODS

    /**
     * Imposta la posizione della bottombar in modo da farla sempre aderire al fondo
     */
    setBottomBarPosition : function () {
        const wh = $(window).height();
        const dh = $(document).height();
        const bbh = this.jqMainBottombarContainer.outerHeight();
        const newTop = wh - bbh;
        const refTop = this.endBodyReference.offset().top;

        if (refTop > newTop) {
            //
            // In this case body height exceed windows height.
            // Then place bottombar at the end of document (body)
            //
            this.jqMainBottombarContainer.offset({
                top: dh - bbh
            });
        }
        else {
            //
            // In this case windows height exceed body height
            // Then place bottombar at the end of window
            //
            this.jqMainBottombarContainer.offset({
                top: (newTop > this.bottomBarOriginalTop) ? newTop : this.bottomBarOriginalTop
            });
        }
    }

    // endregion

}


/**
 * Export del componente
 */
export function jsMainBottomBarInit() {

    jsMainBottomBar.init();
}

