
import Cookies from 'js-cookie';



const jsCookiesService = {

    LEGAL_COOKIE_KEY : 'ologoweb_legal_cookie',

    // region CORE

    /**
     * Entry point
     */
    init : function () {

    },

    /**
     * Return object
     */
    getInstance : function () {

        return this;
    },

    // endregion




    // region LEGAL COOKIE

    getLegalCookie : function () { return this.getStrongCookie(this.LEGAL_COOKIE_KEY); },

    setLegalCookie : function (value) { this.setStrongCookie(this.LEGAL_COOKIE_KEY, value, null); },

    removeLegalCookie : function () { this.removeStrongCookie(this.LEGAL_COOKIE_KEY); },

    // endregion




    // region PRIVATE METHODS

    /**
     * Return expire time
     *
     * @param days
     * @param hours
     * @param minutes
     *
     * @returns {Date}
     */
    expireTime : function (days, hours, minutes) {

        const days2Min = days * 24 * 60;
        const hours2Min = hours * 60;

        return new Date(new Date().getTime() + ((days2Min + hours2Min + minutes) * 60 * 1000));
    },

    // endregion




    // region PRIVATE WRAPPERS

    //
    // Some browsers (as Vivaldi) doesn't manage correctly js-cookie.
    // Then add localStorage management to avoid this problem
    //
    getStrongCookie : function (key) { return  localStorage.getItem(key) ?? Cookies.get (key); },

    setStrongCookie : function (key, value, expire) {
        Cookies.set (key, value, {
            sameSite: 'none', secure: true, expires: expire ?? this.expireTime(20, 0, 0),
        });

        localStorage.setItem(key, value);
    },

    removeStrongCookie : function (key) {
        Cookies.remove (key, {sameSite: 'None', secure: true});

        localStorage.removeItem(key);
    }

    // endregion


}



export function jsCookiesServiceInstance () {

    return jsCookiesService.getInstance();
}