
import {jsCookiesServiceInstance} from './cookiesService';


const jsCookies = {


    // region CORE


    cookies : {

        functional : true,      // This is mandatory true
        analysisAndStat : true,
        captcha : false
    },



    /**
     * Entry point
     */
    init : function () {
        //
        // Get cookie service instance
        //
        this.cookiesService = jsCookiesServiceInstance();
        //
        // Bind components with jquery
        //
        this.bindCmp();

        if (this.jqContainer.length > 0) {

            this.attachEvents();
            //
            // Init some components
            //
            this.initCmp();

            const cookieState = this.cookiesService.getLegalCookie();
            //
            // Show cookie panel only if cookie are not written yet
            //
            if ((cookieState === undefined) || (cookieState === null)) {
                //
                // Finally show cookie panel
                //
                this.jqContainer.show();
            }
            else {
                //
                // Update frontend model with data withdraw from cookies
                //
                this.cookies = JSON.parse(cookieState);
            }

        }
        //
        // Check if update backend session
        //
        this.compareBackendValues();
    },

    /**
     * Collega i componenti
     */
    bindCmp : function () {

        this.defaultCookiesValue = structuredClone (this.cookies);

        this.jqContainer = $('.cookies-container');
        //
        // If container exists then bind the other components
        //
        if (this.jqContainer.length > 0) {

            this.jqCrossClose = this.jqContainer.find('.cross-close');
            this.jqDetailsArea = this.jqContainer.find ('.details-area');
            this.jqChangeSettingsButton = this.jqContainer.find('.cookie-button.change');
            this.jqAcceptAllButton = this.jqContainer.find('.cookie-button.all');
            //
            // Cookie checkbox options
            //
            this.jqCheckFunctionalCookies = this.jqContainer.find('#checkFunctionalCookies');
            this.jqcheckAnalyticsAndStat = this.jqContainer.find('#checkAnalyticsAndStat');
            this.jqcaptchaCookies = this.jqContainer.find('#captchaCookies');
        }

        //
        // External reset cookie button
        //
        this.jqResetCookieButton = $('#legal-cookies-reset-button');
    },

    /**
     * Collega gli eventi
     */
    attachEvents : function () {

        const th = this;
        //
        // Cross close button OnClick
        //
        this.jqCrossClose.off('click').on('click', function () { th.closeAndAcceptMandatory(); });

        //
        // Change/Save Settings button OnClick
        //
        this.jqChangeSettingsButton.off('click').on('click', function () { th.changeSettingsSteps(); });

        //
        // Accept All button OnClick
        //
        this.jqAcceptAllButton.off('click').on('click', function () { th.acceptAll(); });

        //
        // Attach OnClick on Reset Cookie button (if exists)
        //
        if (this.jqResetCookieButton.length > 0) {

            this.jqResetCookieButton.off('click').on('click', function () { th.resetPreferences(); });
        }
    },

    /**
     * Init some components
     */
    initCmp : function () {
        //
        // On enter details area is closed
        //
        this.jqDetailsArea.hide();
        this.changeSettingsIsClicked = false;
        //
        // Update option checkbox state
        //
        this.updateOptionCheckbox();
    },

    // endregion




    // region PRIVATE METHODS

    /**
     * Write cookie with new values
     */
    updateCookie : function () {
        //
        // Update frontend cookies
        //
        this.cookiesService.setLegalCookie(JSON.stringify(this.cookies));
        //
        // Update backend session
        //
        this.updateBackendSession();
    },

    /**
     * Update option checkbox state according to cookies value
     */
    updateOptionCheckbox : function () {

        this.setCheckbox (this.jqCheckFunctionalCookies, this.cookies.functional);
        this.setCheckbox (this.jqcheckAnalyticsAndStat, this.cookies.analysisAndStat);
        this.setCheckbox (this.jqcaptchaCookies, this.cookies.captcha);
    },

    /**
     * Set checkbox state
     *
     * @param jqCtrl    jquery switch
     * @param state     bool            true active; false otherwise
     */
    setCheckbox : function (jqCtrl, state) {

        jqCtrl.prop('checked', state);
    },

    /**
     * Compare backend model (slave) with frontend model (master).
     * If backend doesn't match then frontend sends new values to backend (and backend store them in session)
     */
    compareBackendValues : function () {
        //
        // Get back model values (cookies.html.twig)
        //
        const backendModel = JSON.parse (cookieSession);
        //
        // Compare frontend and backend model
        //
        if (JSON.stringify(this.cookies) !== JSON.stringify(backendModel)) {
            //
            // Backend doesn't match. Update backend session
            //
            this.updateBackendSession();
        }
    },

    // endregion




    // region EVENTS

    /**
     * Cross button onClick : close Cookies panel and accept only mandatory cookies
     */
    closeAndAcceptMandatory : function () {
        //
        // Close cookies container
        //
        this.jqContainer.hide();
        //
        // Accept mandatory cookies only
        //
        this.cookies.functional = true;
        this.cookies.analysisAndStat = false;
        this.cookies.captcha = false;
        //
        // Write cookie
        //
        this.updateCookie();
    },

    /**
     * Manage Change Settings and Save Settings buttons onClick event
     */
    changeSettingsSteps : function () {
        //
        // Check if button is in Change Settings or Save Settings state
        //
        if (this.changeSettingsIsClicked) {
            //
            // Save Settings state
            //
            this.cookies.functional = true;
            this.cookies.analysisAndStat = this.jqcheckAnalyticsAndStat.is(":checked");
            this.cookies.captcha = this.jqcaptchaCookies.is(":checked");
            //
            // Write cookie
            //
            this.updateCookie();
            //
            // Close panel
            //
            this.jqContainer.hide();
        }
        else {
            //
            // Change Settings state
            // In this case open details panel and change button label (and meanings)
            //
            this.jqDetailsArea.show();
            this.jqChangeSettingsButton.text(this.jqChangeSettingsButton.data('savetxt'));
            this.changeSettingsIsClicked = true;
        }
    },

    /**
     * Accept All button onClick: set all cookies as active
     */
    acceptAll : function () {
        //
        // User accepted all cookies
        //
        this.cookies.functional = true;
        this.cookies.analysisAndStat = true;
        this.cookies.captcha = true;
        //
        // Write cookie
        //
        this.updateCookie();
        //
        // Close panel
        //
        this.jqContainer.hide();
    },

    /**
     * Reset cookies preferences and show cookies panel
     */
    resetPreferences : function () {

        this.cookies = structuredClone (this.defaultCookiesValue);

        this.cookiesService.removeLegalCookie();

        this.updateOptionCheckbox();

        this.jqContainer.show();
    },

    // endregion




    // region REST

    /**
     * Update backend session
     */
    updateBackendSession : function () {

        $.ajax({
            url: '/cookies/updatesession',
            method: 'POST',
            data: JSON.stringify (this.cookies)
        })
            .done(function (data) {

            })
            .fail(function (xhr) {

            })
            .always(function () {

            });
    }

    // endregion

}


$(function () {

    jsCookies.init();
});




